import { createStore } from "redux";

const initialState = {
    userData: {
        authenticated: false
    },
    managerConfig: {
        ubicacion: null,
    },
    bill: [],
    newPassword: "",
    userPermissions: {},
    ventaDiferida: null,
    backgroundColors: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)',
        'rgba(173,216,230, 0.2)',
        'rgba(240,128,128, 0.2)',
        'rgba(224,255,255, 0.2)',
        'rgba(250,250,210, 0.2)',
        'rgba(211,211,211, 0.2)',
        'rgba(144,238,144, 0.2)',
        'rgba(211,211,211, 0.2)',
        'rgba(255,182,193, 0.2)',
        'rgba(255,160,122, 0.2)',
        'rgba(32,178,170, 0.2)',
        'rgba(135,206,250, 0.2)',
        'rgba(119,136,153, 0.2)',
        'rgba(176,196,222, 0.2)',
        'rgba(255,255,224, 0.2)',
    ],
    borderColors: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
        'rgba(173,216,230, 1)',
        'rgba(240,128,128, 1)',
        'rgba(224,255,255, 1)',
        'rgba(250,250,210, 1)',
        'rgba(211,211,211, 1)',
        'rgba(144,238,144, 1)',
        'rgba(211,211,211, 1)',
        'rgba(255,182,193, 1)',
        'rgba(255,160,122, 1)',
        'rgba(32,178,170, 1)',
        'rgba(135,206,250, 1)',
        'rgba(119,136,153, 1)',
        'rgba(176,196,222, 1)',
        'rgba(255,255,224, 1)',
    ],
}

const reducer = (state = initialState, action) => {
    switch(action.type){
        case "USER_PERFORM_LOGIN":
            return {
                ...state,
                userData: action.user_data
            }
        case "USER_PERFORM_LOGOUT":
            return {
                ...state,
                userData: action.user_data
            }
        case "USER_ADD_PRODUCT_IN_BILL":
            return {
                ...state,
                bill: action.bill
            }
        case "USER_DROP_PRODUCT_FROM_BILL":
            return {
                ...state,
                bill: action.bill
            }
        case "EDIT_VENTA_DIFERIDA":
            return {
                ...state,
                ventaDiferida: action.ventaDiferida
            }
        case "USER_REQUEST_NEW_PASSWORD":
            return {
                ...state,
                newPassword: action.new_password
            }
        case "SET_USER_PERMISSIONS":
            return {
                ...state,
                userPermissions: action.rol_data
            }
        case "CLEAN_UP_BILL":
            return {
                ...state,
                bill: []
            }
        case "CLEAN_UP_VENTA_DIFERIDA":
            return {
                ...state,
                ventaDiferida: null
            }
        case "UPDATE_USER_CONFIG":
            return {
                ...state,
                userConfig: action.user_config
            }
        default:
            break;
    }
    return state;
}

export default createStore(reducer);