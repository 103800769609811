const dev = {
    apiGateway: {
        REGION: "us-east-2",
        URL: "https://klu70mtwgj.execute-api.us-east-2.amazonaws.com/dev"
    },
    cognito: {
        REGION: "us-east-2",
        USER_POOL_ID: "us-east-2_5SuWbVUt8",
        APP_CLIENT_ID: "7trnmlpu7if416dfvrcdi6qik1",
        IDENTITY_POOL_ID: "us-east-2:ebe3f95c-325d-46bb-a289-7724b56e8ee8"
    },
    s3: {
      REGION: "us-east-2",
      BUCKET: "api-cristal-dev-storebucket-1c6tktuytpf8n",
    },
    stripe_pk: "pk_test_"
};

const prod = {
  apiGateway: {
    REGION: "us-east-2",
    URL: "https://msdf1t9179.execute-api.us-east-2.amazonaws.com/prod"
  },
  cognito: {
      REGION: "us-east-2",
      USER_POOL_ID: "us-east-2_aKJ7hOs1f",
      APP_CLIENT_ID: "2l07ugjmbune6okagjduba9onr",
      IDENTITY_POOL_ID: "us-east-2:64ef0e2b-6c7f-4b0b-9b9c-26ad95653ffd"
  },
  s3: {
    REGION: "us-east-2",
    BUCKET: "api-cristal-prod-storebucket-1z2dj00htal9",
  },
};

// Si no se especifica el ambiente (dev ó prod), se asume que el ambiente es dev
const state = process.env.REACT_APP_STAGE === 'prod'
  ? prod
  : dev;

const config = {
    // Aca las configuraciones para ambos ambientes
    customUser: "userTest",
    customUserPass: "userTestPass",
    SENTRY_DSN: "https://4752d175ddce4688846e45103370c9a3@o1010058.ingest.sentry.io/5974465",
    ...state
  };

export default config;
/*export default {
  // Aca las configuraciones para ambos ambientes
  customUser: "userTest",
  customUserPass: "userTestPass",
  ...config
};*/