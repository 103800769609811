import React from "react"
import { Route, Switch } from "react-router-dom";
// import Cristal from "./containers/cristal.js";
// import Login from "./containers/login.js";
import NoService from "./containers/noService.js";

export default function Routes (){
    return (
        <Switch>
            <Route exact path="/">
                {/* <Login /> */}
                <NoService />
            </Route>
            <Route exact path="/cristal">
                {/* <Cristal /> */}
                <NoService />
            </Route>
        </Switch>
    );
}